import { JsonObject, JsonProperty } from 'json2typescript'
import DirectDebitQuote from './DirectDebitQuote'
import Endorsement from './Endorsement'
import Fee from './Fee'
import Model from './Model'
import Scheme from './Scheme'
import SchemeCoverDetail from './SchemeCoverDetail'
import SubscriptionResult from './SubscriptionResult'
import VAP from '@/services/models/VAP'

@JsonObject('SchemeQuoteResult')
export default class SchemeQuoteResult extends Model {
  @JsonProperty('schemeName', String)
  schemeName = ''

  @JsonProperty('scheme', Scheme)
  scheme: Scheme = new Scheme()

  @JsonProperty('calculatedGrossPremiumInclIPT', Number)
  calculatedGrossPremiumInclIPT = 0

  @JsonProperty('calculatedNetPremiumExclIPT', Number)
  calculatedNetPremiumExclIPT = 0

  @JsonProperty('calculatedPremiumIPT', Number)
  calculatedPremiumIPT = 0

  @JsonProperty('calculatedGrossPremiumInclIPTAndFees', Number)
  calculatedGrossPremiumInclIPTAndFees = 0

  @JsonProperty('fees', Number)
  fees = 0

  @JsonProperty('feesBreakdown', [Fee])
  feesBreakdown: Fee[] = []

  @JsonProperty('calculatedTotalPayable', Number)
  calculatedTotalPayable = 0

  @JsonProperty('endorsements', [Endorsement])
  endorsements: Endorsement[] = []

  @JsonProperty('directDebitQuote', DirectDebitQuote)
  directDebitQuote: DirectDebitQuote = new DirectDebitQuote()

  @JsonProperty('coverLevelDetail', SchemeCoverDetail)
  coverLevelDetail: SchemeCoverDetail = new SchemeCoverDetail()

  @JsonProperty('status', String)
  status = ''

  @JsonProperty('quoteReference', String)
  quoteReference = ''

  @JsonProperty('subscriptionResult', SubscriptionResult, true)
  subscriptionResult: SubscriptionResult | null = null

  @JsonProperty('vaps', [VAP])
  vaps: VAP[] = []

  @JsonProperty('additional', Number)
  additional = 0
}

@JsonObject('CarSchemeQuoteResult')
export class CarSchemeQuoteResult extends SchemeQuoteResult {
  @JsonProperty('compulsoryExcess', Number)
  compulsoryExcess = 0

  @JsonProperty('noClaimsProtected', Boolean)
  noClaimsProtected = false

  @JsonProperty('ncdProtectionCost', Number)
  ncdProtectionCost = 0

  @JsonProperty('failedNCP', Boolean)
  failedNCP = false

  @JsonProperty('windscreenExcess', Number)
  windscreenExcess = false

  @JsonProperty('windscreenRepairExcess', Number)
  windscreenRepairExcess = false

  @JsonProperty('allowedToDriveOtherVehicles', Boolean)
  allowedToDriveOtherVehicles = false

  @JsonProperty('voluntaryExcess', Number)
  voluntaryExcess = 0
}

@JsonObject('PetSchemeQuoteResult')
export class PetSchemeQuoteResult extends SchemeQuoteResult {
  @JsonProperty('compulsoryExcess', Number)
  compulsoryExcess = 0
}

@JsonObject('VehicleSchemeQuoteResult')
export class VehicleSchemeQuoteResult extends SchemeQuoteResult {
  @JsonProperty('compulsoryExcess', Number)
  compulsoryExcess = 0
}

@JsonObject('PropertySchemeQuoteResult')
export class PropertySchemeQuoteResult extends SchemeQuoteResult {
  @JsonProperty('buildingsExcess', Number)
  buildingsExcess = 0

  @JsonProperty('subsidenceExcess', Number)
  subsidenceExcess = 0

  @JsonProperty('contentsSpecifiedItemsExcess', Number)
  contentsSpecifiedItemsExcess = 0

  @JsonProperty('contentsAllRiskExcess', Number)
  contentsAllRiskExcess = 0

  @JsonProperty('buildingsFloodExcess', Number)
  buildingsFloodExcess = 0

  @JsonProperty('buildingsWaterExcess', Number)
  buildingsWaterExcess = 0

  @JsonProperty('contentsFloodExcess', Number)
  contentsFloodExcess = 0

  @JsonProperty('contentsWaterExcess', Number)
  contentsWaterExcess = 0
}

@JsonObject('TouringCaravanSchemeQuoteResult')
export class TouringCaravanSchemeQuoteResult extends SchemeQuoteResult {
  @JsonProperty('compulsoryExcess', Number)
  compulsoryExcess = 0

  @JsonProperty('voluntaryExcess', Number)
  voluntaryExcess = 0
}
