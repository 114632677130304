import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type IResponseMapper from './interfaces/IResponseMapper'
import type IAggregatorService from './interfaces/IAggregatorService'
import AggregatorClient from './models/AggregatorClient'
import AggregatorQuoteReference from './models/AggregatorQuoteReference'

export default class AggregatorService implements IAggregatorService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  responseMapper: IResponseMapper

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert, responseMapper: IResponseMapper) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
    this.responseMapper = responseMapper
  }

  async getClientByAggregatorGuid(guid: string): Promise<AggregatorClient> {
    const result = await this.axios.get(`${this.apiUrl}/aggregator/client/${encodeURIComponent(guid)}`)
    return this.jsonConvert.deserializeObject(result.data, AggregatorClient)
  }

  async getQuoteByGuid(guid: string): Promise<AggregatorQuoteReference> {
    const result = await this.axios.get(`${this.apiUrl}/aggregator/quote/${guid}`)
    return this.jsonConvert.deserializeObject(result.data, AggregatorQuoteReference)
  }

  async getAggGuid(): Promise<string> {
    const result = await this.axios.get(`${this.apiUrl}/aggregator/get-guid`)
    return result.data
  }
}
